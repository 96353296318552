import { Button } from '@spacefill/uikit/src/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClipboardCheck, faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { ChipNumber } from '@spacefill/uikit/src/components/ChipNumber';
import React, { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Backend from '@spacefill/shared/src/utils/Backend';
import { SidePanel } from '@spacefill/uikit/src/components/SidePanel';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@spacefill/uikit/src/components/Dropdown';
import { toast } from '@spacefill/uikit/src/components/Toast/createToast';

import { InAppNotificationsList } from './InAppNotificationsList';
import { RequiredActionsList } from './RequiredActionsList';

const COUNTS_REFRESH_INTERVAL = 5 * 60000;

export function NotificationsSidePanel() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [sidePanelVisible, setSidePanelVisible] = useState(false);
    const [sidePanelContentType, setSidePanelContentType] = useState('');

    const {
        data: { count: unreadNotificationsTotalCount },
        isFetching: isFetchingNotifications,
    } = useQuery({
        queryKey: ['in-app-notifications/count/unread'],
        queryFn: () => Backend.call('in-app-notifications/count/unread'),
        placeholderData: {},
        refetchInterval: COUNTS_REFRESH_INTERVAL,
    });

    const {
        data: { count: openTodoTasksTotalCount },
        isFetching: isFetchingTodoTasks,
    } = useQuery({
        queryKey: ['todo-tasks/count/open'],
        queryFn: () => Backend.call('todo-tasks/count/open'),
        placeholderData: {},
        refetchInterval: COUNTS_REFRESH_INTERVAL,
    });

    const { mutate: readAllMutate } = useMutation({
        mutationFn: () => Backend.call('in-app-notifications/read-all', {}, 'POST'),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey: ['in-app-notifications'],
            });
            queryClient.invalidateQueries({
                queryKey: ['in-app-notifications/count/unread'],
            });
        },
        onError(error) {
            console.error('error during readAll mutation: ', error);
            toast.error(t('Internal error!'));
        },
    });

    function onClickNotifications() {
        setSidePanelContentType('notifications');
        setSidePanelVisible(true);
    }

    function onClickTodoTasks() {
        setSidePanelContentType('todoTasks');
        setSidePanelVisible(true);
    }

    const markAllAsReadButton = (
        <Dropdown
            small
            align='right'
            iconEnd={
                <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className='h-5 text-gray-500 px-1 py-[1px]'
                />
            }
            options={[
                {
                    content: <>{t('Mark all as read')}</>,
                    onClick: () => {
                        readAllMutate();
                    },
                },
            ]}
        />
    );

    return (
        <div className='flex gap-2'>
            <Button
                small
                iconStart={<FontAwesomeIcon icon={faClipboardCheck} />}
                variant='secondary'
                onClick={onClickTodoTasks}
            >
                {!isFetchingTodoTasks && openTodoTasksTotalCount > 0 && (
                    <ChipNumber
                        variant={'red'}
                        value={openTodoTasksTotalCount > 99 ? '99+' : openTodoTasksTotalCount}
                    />
                )}
            </Button>

            <Button
                small
                iconStart={<FontAwesomeIcon icon={faBell} />}
                variant='secondary'
                onClick={onClickNotifications}
            >
                {!isFetchingNotifications && unreadNotificationsTotalCount > 0 && (
                    <ChipNumber
                        variant={'red'}
                        value={unreadNotificationsTotalCount > 99 ? '99+' : unreadNotificationsTotalCount}
                    />
                )}
            </Button>

            <SidePanel
                isOpen={sidePanelVisible}
                onClose={() => setSidePanelVisible(false)}
                title={
                    <>
                        {sidePanelContentType === 'notifications' && (
                            <span className='flex items-center gap-2'>
                                <FontAwesomeIcon
                                    icon={faBell}
                                    className='text-gray-500'
                                />
                                {t('Notifications')}

                                {unreadNotificationsTotalCount > 0 && (
                                    <ChipNumber
                                        variant={'light'}
                                        value={
                                            unreadNotificationsTotalCount > 99 ? '99+' : unreadNotificationsTotalCount
                                        }
                                    />
                                )}
                            </span>
                        )}
                        {sidePanelContentType === 'todoTasks' && (
                            <h2 className='flex items-center gap-2'>
                                <FontAwesomeIcon
                                    icon={faClipboardCheck}
                                    className='text-gray-500'
                                />
                                {t('Required actions')}

                                {openTodoTasksTotalCount > 0 && (
                                    <ChipNumber
                                        variant={'light'}
                                        value={openTodoTasksTotalCount > 99 ? '99+' : openTodoTasksTotalCount}
                                    />
                                )}
                            </h2>
                        )}
                    </>
                }
                titleSideElement={
                    sidePanelContentType === 'notifications' && unreadNotificationsTotalCount > 0 && markAllAsReadButton
                }
            >
                {sidePanelContentType === 'notifications' && <InAppNotificationsList />}
                {sidePanelContentType === 'todoTasks' && <RequiredActionsList />}
            </SidePanel>
        </div>
    );
}
