import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '../../../types/user';

export interface UserFullnameProps {
    firstName: User['firstName'];
    lastName: User['lastName'];
    isDeleted: boolean;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLSpanElement>;
}

export const UserFullname = ({ firstName, lastName, isDeleted, dataTestid, htmlAttributes }: UserFullnameProps) => {
    const { t } = useTranslation();

    return (
        <span
            data-testid={dataTestid}
            {...htmlAttributes}
        >
            {isDeleted ? t('Deleted user') : `${firstName} ${lastName}`}
        </span>
    );
};
