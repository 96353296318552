import { faWarehouse as faDuotoneWarehouse } from '@fortawesome/pro-duotone-svg-icons';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';

type CompanyLogoProps = {
    logoUrl: string;
    fromNavBar?: boolean;
    isActive?: boolean;
};

const CompanyLogo = ({ logoUrl, fromNavBar = false, isActive = false }: CompanyLogoProps) => (
    <div
        className={clsx(
            'relative flex shrink-0 cursor-pointer overflow-hidden rounded-md',
            fromNavBar ? 'size-8' : 'size-12',
            {
                'bg-white': logoUrl,
                'border border-solid border-gray-200': logoUrl && !fromNavBar,
                transparent: !logoUrl && fromNavBar,
                'bg-gray-200': !logoUrl && !fromNavBar,
            }
        )}
    >
        {logoUrl ? (
            <img
                src={logoUrl}
                alt='Company logo'
                className='w-full object-contain'
            />
        ) : fromNavBar ? (
            <FontAwesomeIcon
                icon={faWarehouse}
                className={clsx('m-auto', isActive ? 'text-black' : 'text-white')}
            />
        ) : (
            <FontAwesomeIcon
                icon={faDuotoneWarehouse}
                className='m-auto text-gray-500'
            />
        )}
    </div>
);

export { CompanyLogo };
