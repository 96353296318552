import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { componentStyleReset } from 'mineral-ui/styles';

import { breadcrumbTheme, breadcrumbItemTheme } from './Themes';

export const BreadcrumbRoot = styled('nav', {
    shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme: baseTheme }) => {
    const theme = breadcrumbTheme(baseTheme);

    return {
        ...componentStyleReset(baseTheme),
        padding: 0,
        '> OL': {
            flexWrap: 'wrap',
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'row',
            margin: `${baseTheme.space_stack_md} 0`,
            padding: `${baseTheme.space_stack_md} 0`,
            borderBottom: `1px solid ${theme.BreadCrumb_borderColor}`,
        },
    };
});

export const BreadcrumbItemRoot = styled('li', {
    shouldForwardProp: (prop) => isPropValid(prop),
})(({ selected, theme: baseTheme }) => {
    const theme = breadcrumbItemTheme(baseTheme);

    return {
        ...componentStyleReset(baseTheme),
        '> .sep': {
            marginLeft: baseTheme.space_stack_xs,
            marginRight: baseTheme.space_stack_xs,
            fontSize: theme.BreadCrumbItem_fontSize,
        },
        '> A': {
            fontSize: theme.BreadCrumbItem_fontSize,
            color: selected ? theme.BreadCrumbItem_color_selected : theme.BreadCrumbItem_color,
            textDecoration: 'none',
            marginLeft: baseTheme.space_stack_xs,
            marginRight: baseTheme.space_stack_xs,
            '&:hover': {
                color: selected ? theme.BreadCrumbItem_color_selected : theme.BreadCrumbItem_color_hover,
                textDecoration: 'underline',
            },
            '&:focus': {
                color: selected ? theme.BreadCrumbItem_color_selected : theme.BreadCrumbItem_color_focus,
            },
        },
        '&:last-of-type .sep': {
            display: 'none',
        },
    };
});
