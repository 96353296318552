import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface EmptyStateProps {
    icon?: IconDefinition;
    picture?: {
        src: string;
        width: number;
        height: number;
    };
    paragraph: string;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const EmptyState = ({ icon, picture, paragraph, dataTestid, htmlAttributes }: EmptyStateProps) => (
    <div
        className='flex flex-col items-center gap-y-2'
        data-testid={dataTestid}
        {...htmlAttributes}
    >
        <div className='shrink'>
            {icon && (
                <FontAwesomeIcon
                    icon={icon}
                    className='text-3xl text-gray-500'
                />
            )}
            {picture && (
                <img
                    src={picture.src}
                    width={picture.width}
                    height={picture.height}
                    alt=''
                />
            )}
        </div>
        <p className='max-w-md whitespace-pre-line text-center text-sm text-gray-500'>{paragraph}</p>
    </div>
);
