import { useEffect, useState } from 'react';
import constate from 'constate';

import { useUserContext } from '../UserContext';
import { getEnvironmentVariable } from '../GetEnvVar';

const useBeamer = () => {
    const [beamer, setBeamer] = useState({});
    const { user } = useUserContext();

    useEffect(() => {
        const beamerId = getEnvironmentVariable('beamerId');
        if (beamerId) {
            window.beamer_config = {
                button: false,
                product_id: getEnvironmentVariable('beamerId'),
                filter: `${
                    user?.impersonator
                        ? 'IMPERSONATED_USER'
                        : user?.role === 'SHIPPER'
                        ? user?.customer?.orderManagementEnabled
                            ? 'SHIPPER'
                            : 'SHIPPER_START'
                        : user?.role
                }`,
                language: user?.preferredLanguage,
                user_firstname: user?.firstName,
                user_lastname: user?.lastName,
                user_email: user?.email,
                user_id: user?.id,
            };
            const script = document.createElement('script');

            script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            script.async = true;
            script.type = 'text/javascript';
            script.onload = () => {
                setBeamer(window?.Beamer ?? {});
            };

            user?.logged && document.body.appendChild(script);

            return () => {
                user?.logged && document.body.removeChild(script);
            };
        } else {
            console.info('No Beamer config found, skipping');
            setBeamer({
                show: () => {
                    console.error('Beamer is not loaded');
                },
            });
        }
    }, [user]);

    return beamer;
};

const [BeamerProvider, useBeamerContext] = constate(useBeamer);

export { BeamerProvider, useBeamerContext };
