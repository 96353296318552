import { useState, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';

interface AccordionProps {
    title: ReactNode;
    content: ReactNode;
}
export const Accordion = ({ title, content }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className='flex w-full items-center  focus:outline-none'
                style={{
                    WebkitUserSelect: 'text',
                    MozUserSelect: 'text',
                    msUserSelect: 'text',
                    userSelect: 'text',
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>{title}</span>
                <span className={`transition-transform${isOpen ? '' : ' rotate-180'} ml-2`}>
                    <FontAwesomeIcon
                        icon={faChevronUp}
                        className='text-gray-500'
                    />
                </span>
            </button>
            {isOpen && (
                <div>
                    <div>{content}</div>
                </div>
            )}
        </>
    );
};
