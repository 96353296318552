import { clsx } from 'clsx';
import { HTMLAttributes } from 'react';

export interface DividerProps {
    orientation?: 'horizontal' | 'vertical';
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    className?: string;
}

export const Divider = ({ orientation = 'horizontal', dataTestid, htmlAttributes, className }: DividerProps) => (
    <hr
        className={clsx(
            // Common styles
            ['border-gray-300'],
            // Variant styles
            {
                'w-full': orientation === 'horizontal',
                'my-6': orientation === 'horizontal',
                'border-r': orientation === 'vertical',
                'border-t-0': orientation === 'vertical',
                'w-fit': orientation === 'vertical',
                'h-[inherit]': orientation === 'vertical',
                'self-stretch': orientation === 'vertical',
                'mx-2': orientation === 'vertical',
            },
            className
        )}
        data-testid={dataTestid}
        {...htmlAttributes}
    />
);
