import React from 'react';
import { Navigate } from 'react-router-dom';

const WarehousesList = async () =>
    import('../../pages/warehouses/List').then((module) => ({ Component: module.WarehousesList }));
const WarehouseNew = async () =>
    import('../../pages/warehouses/New').then((module) => ({ Component: module.WarehouseNew }));
const WarehouseEdit = async () =>
    import('../../pages/warehouses/Edit').then((module) => ({ Component: module.WarehouseEdit }));

const getWarehouseRoutes = (user, _features) => {
    if (user.role === 'PROVIDER') {
        return [
            // Warehouses list
            {
                path: '/warehouses/',
                lazy: WarehousesList,
            },
            // New warehouse
            {
                path: '/warehouses/new',
                lazy: WarehouseNew,
            },
            // Warehouses edit
            {
                path: '/warehouses/:warehouseId/edit',
                lazy: WarehouseEdit,
            },
            // Default
            {
                path: '/warehouses/*',
                element: <Navigate to='/' />,
            },
        ];
    } else {
        return [
            {
                path: '/warehouses/*',
                element: <Navigate to='/' />,
            },
        ];
    }
};

export { getWarehouseRoutes };
