/** @jsxRuntime classic */
import React, { Fragment, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { call } from '@spacefill/shared/src/utils/Backend';
import { Combobox } from '@spacefill/uikit/src/components/Combobox/Combobox';

export function UserAdminSwitcher() {
    const [searchCuQuery, setCuQuery] = useState('');

    const { data: dataCustomerLsp } = useQuery({
        queryKey: ['lsp-cus', searchCuQuery],
        queryFn: async () => call('admin/list-customers-lsp', { name: searchCuQuery }, 'POST'),
    });

    return (
        <Combobox
            placeholder={'Choose a customer/Lsp'}
            options={dataCustomerLsp || []}
            formatOption={(value) => {
                return (
                    <>
                        [{value.value.type.slice(0, 1)}] {value.value.name}
                    </>
                );
            }}
            setValue={async (value) => {
                await call(
                    '/users/',
                    {},
                    'GET',
                    value.type === 'CUSTOMER' ? { 'x-customer-id': value.id } : { 'x-lsp-id': value.id }
                );
                localStorage.clear();

                window.location = '';
            }}
            onSearch={(query) => {
                setCuQuery(query);
            }}
        />
    );
}
