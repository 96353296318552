import { Fragment, HTMLAttributes } from 'react';
import type { BreadcrumbData } from 'use-react-router-breadcrumbs';

import { BreadcrumbItem } from './BreadcrumbItem';

export interface BreadcrumbProps {
    breadcrumbs: BreadcrumbData[];
    separator?: string;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLElement>;
}

export const Breadcrumb = ({ breadcrumbs, separator = '/', dataTestid, htmlAttributes }: BreadcrumbProps) => {
    const breadcrumbHiddenItems = ['/logistic-management'];

    return (
        <ol
            id='breadcrumb'
            className='flex flex-row items-center'
            data-testid={dataTestid}
            {...htmlAttributes}
        >
            {breadcrumbs.map(({ key, match, breadcrumb, location }, idx) => {
                if (breadcrumbHiddenItems.includes(key)) return null;

                return (
                    <Fragment key={key}>
                        <BreadcrumbItem
                            href={match.pathname}
                            state={`${match.pathname}/`.match(location.pathname) ? 'active' : 'default'}
                            dataTestid='breadcrumb-item'
                        >
                            {breadcrumb}
                        </BreadcrumbItem>
                        {idx !== breadcrumbs.length - 1 && <li className='text-sm text-gray-600'>{separator}</li>}
                    </Fragment>
                );
            })}
        </ol>
    );
};
