export const breadcrumbTheme = (baseTheme) => ({
    BreadCrumb_borderColor: baseTheme.palette.gray[40],
    ...baseTheme,
});

export const breadcrumbItemTheme = (baseTheme) => ({
    BreadCrumbItem_fontSize: baseTheme.fontSize_ui,
    BreadCrumbItem_color: baseTheme.palette.gray[70],
    BreadCrumbItem_color_hover: baseTheme.palette.gray[100],
    BreadCrumbItem_color_focus: baseTheme.palette.blue[90],
    BreadCrumbItem_color_selected: baseTheme.palette.primary_color[90],
    ...baseTheme,
});
