import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ApolloProvider } from '@apollo/client';
import { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';
import { UserProvider } from '@spacefill/shared/src/utils/UserContext';
import { BeamerProvider } from '@spacefill/shared/src/utils/Beamer';
import { TopBannerContextProvider } from '@spacefill/shared/src/utils/TopBannerContext';
import { ThemeStateProvider } from '@spacefill/shared/src/utils/ThemeStateContext';
import { FeatureTogglingProvider, FeatureTogglingToolbar } from '@spacefill/shared/src/utils/FeatureToggling';
import { WarehouseNamingContextProvider } from '@spacefill/shared/src/utils/WarehouseNamingContext';
import { SpacefillThemeProvider } from '@spacefill/shared/src/components/Theme';
import { client as ApolloClient } from '@spacefill/shared/src/utils/Apollo';
import '@spacefill/shared/src/utils/YupMethods';

import { featureSettings } from './Features';
import { RouterContext } from './router/RouterContext';
import '@spacefill/uikit/src/index.css';

const queryClient = new QueryClient();
const cookies = new Cookies();

const App = () => {
    // Need to put it here because Apollo rerender at login and when in
    // impersonate mode it removes cookies before user is connected
    if (new URL(window.location.href).searchParams.get('impersonateUserId')) {
        cookies.remove('jwtTokenExternalConsole', {
            domain: document.domain,
            path: '/',
        });
        cookies.remove('jwtTokenExternalConsoleImpersonator', {
            domain: document.domain,
            path: '/',
        });
        cookies.remove('jwtTokenRememberMe', {
            domain: document.domain,
            path: '/',
        });
    }
    return (
        <QueryClientProvider client={queryClient}>
            <ApolloProvider client={ApolloClient}>
                <TopBannerContextProvider>
                    <UserProvider>
                        <BeamerProvider>
                            <FeatureTogglingProvider featureSettings={featureSettings}>
                                <ThemeStateProvider>
                                    <SpacefillThemeProvider>
                                        <WarehouseNamingContextProvider>
                                            <RouterContext />
                                            <FeatureTogglingToolbar />
                                            <Toaster />
                                        </WarehouseNamingContextProvider>
                                    </SpacefillThemeProvider>
                                </ThemeStateProvider>
                            </FeatureTogglingProvider>
                        </BeamerProvider>
                    </UserProvider>
                </TopBannerContextProvider>
            </ApolloProvider>
            {import.meta.env?.VITE_EXTERNAL_CONSOLE_REACT_QUERY_DEVTOOLS_ENABLED && (
                <ReactQueryDevtools initialIsOpen />
            )}
        </QueryClientProvider>
    );
};

export { App };
