/** @jsxRuntime classic */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import {
    faArrowRightToBracket,
    faBook,
    faBoxesStacked,
    faBuilding,
    faChartSimple,
    faCommentsAlt,
    faFileMagnifyingGlass,
    faFolderOpen,
    faGear,
    faGraduationCap,
    faHandHoldingBox,
    faLayerPlus,
    faQuestionCircle,
    faRoute,
    faScrewdriverWrench,
    faUser,
    faWarehouse,
    faWarehouseFull,
    faDiamondExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { Sidebar } from '@spacefill/uikit/src/components/Sidebar';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { PremiumAccessModal } from '@spacefill/uikit/src/business-components/modals/PremiumAccessModal';
import { getWarehouseLabel } from '@spacefill/shared/src/components/warehouses/configuration/Helpers';
import { ZendeskCategories } from '@spacefill/shared/src/constants/Zendesk';
import { useWarehouseNamingContext } from '@spacefill/shared/src/utils/WarehouseNamingContext';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';
import { call } from '@spacefill/shared/src/utils/Backend';

import { UserAdminSwitcher } from './UserSwitcher';

const ExternalConsoleLeftNav = () => {
    const { t } = useTranslation();
    const { user, switchRole } = useUserContext();
    const location = useLocation();
    const attachmentsUrl = getEnvironmentVariable('attachmentsUrl');
    const [isAnalyticsModalOpen, setAnalyticsModalOpen] = useState(false);

    const [expandedMenu, setExpandedMenu] = useState(location.pathname.split('/')[3]);
    const canUserSeeLogisticManagementMenus =
        (user?.customerWarehouseConfigurationsWithLogisticManagement ?? []).length > 0;
    const { warehouseNamingNeedsUpdate, setWarehouseNamingNeedsUpdate } = useWarehouseNamingContext();
    const isStartUser = user?.role === 'SHIPPER' && !user?.features?.orderManagementEnabled;

    let logo = null;
    if (user?.role === 'SHIPPER' && user.customer.platformLogoSvg) {
        logo = `data:image/svg+xml;base64,${btoa(user.customer.platformLogoSvg.trim())}`;
    } else if (user?.role === 'PROVIDER' && user.logisticProvider.platformLogoSvg) {
        logo = `data:image/svg+xml;base64,${btoa(user.logisticProvider.platformLogoSvg.trim())}`;
    }

    let linkTrainingCenter = '';
    switch (user?.preferredLanguage) {
        case 'DE':
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'wtrTlUxO' : 'cAyzu1Cm';
            if (isStartUser) {
                linkTrainingCenter = 'ouMj4kTu';
            }
            break;
        case 'FR':
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'S8X42CWA' : 'VTMW7XPu';
            if (isStartUser) {
                linkTrainingCenter = 'QbDGf4MT';
            }
            break;
        default:
            linkTrainingCenter = user?.role === 'SHIPPER' ? 'xRBC4Mg7' : 'mRqv6s0J';
            if (isStartUser) {
                linkTrainingCenter = 'ggQbgZBL';
            }
            break;
    }

    const { data, refetch } = useReactQuery({
        queryKey: ['customers/warehouse-configuration-full'],
        queryFn: () => call('customers/warehouse-configuration-full'),
        placeholderData: [],
    });

    const uniqueWarehouseIds = useMemo(() => uniqBy(data ?? [], 'warehouse.id').map((e) => e.warehouseId), [data]);

    const { data: pdfConfigs } = useReactQuery({
        queryKey: ['email-to-order/pdf-configuration/getAll'],
        queryFn: () => call('email-to-order/pdf-configuration/getAll', {}, 'POST'),
        placeholderData: [],
    });
    const { data: countObject } = useReactQuery({
        queryKey: ['unconfirmedorders/getAll'],
        queryFn: () => call('/unconfirmedorders/getAll', {}, 'GET'),
        placeholderData: {},
        enabled: !!uniqueWarehouseIds.length,
    });

    useEffect(() => {
        if (uniqBy(data ?? [], 'warehouseId')?.length === 1) {
            setExpandedMenu(data?.[0]?.warehouse?.id);
        }
    }, [data]);

    useEffect(() => {
        async function updateWarehouseNaming() {
            if (warehouseNamingNeedsUpdate) {
                await refetch();
                setWarehouseNamingNeedsUpdate(false);
            }
        }
        updateWarehouseNaming();
    }, [warehouseNamingNeedsUpdate, refetch, setWarehouseNamingNeedsUpdate]);

    useEffect(() => setExpandedMenu(location?.pathname?.split('/')?.[3]), [location]);

    return (
        <Fragment>
            <PremiumAccessModal
                contentKey='analytics'
                featureNameForTracking='kpi_page_sidebar'
                isOpen={isAnalyticsModalOpen}
                onClose={() => setAnalyticsModalOpen(false)}
                user={user}
                dataTestid='analytics-premium-access-modal'
            />
            <Sidebar
                logo={logo}
                showUserSwitcher={user.isAdmin && <UserAdminSwitcher />}
                mainNavigation={[
                    {
                        title: t('Orders'),
                        icon: <FontAwesomeIcon icon={faRoute} />,
                        isActive: location.pathname === '/logistic-management/orders/',
                        to: '/logistic-management/orders/',
                        dataTestid: 'nav-orders',
                        showIf:
                            (!(user?.customer?.isLoaded ?? false) || canUserSeeLogisticManagementMenus) &&
                            data?.length > 1,
                    },
                    {
                        title: t('Stock situation'),
                        icon: <FontAwesomeIcon icon={faBoxesStacked} />,
                        isActive: location.pathname.includes('/logistic-management/inventory/stock-situation/'),
                        to: '/logistic-management/inventory/stock-situation/references/',
                        dataTestid: 'nav-stock',
                        showIf:
                            (!(user?.customer?.isLoaded ?? false) || canUserSeeLogisticManagementMenus) &&
                            data?.length > 1,
                    },
                    {
                        title: t('References'),
                        icon: <FontAwesomeIcon icon={faBook} />,
                        isActive: location.pathname.includes('/logistic-management/inventory/items/'),
                        to: '/logistic-management/inventory/items/',
                        dataTestid: 'nav-ref',
                        showIf: !(user?.customer?.isLoaded ?? false) || canUserSeeLogisticManagementMenus,
                    },
                    {
                        title: t('Analytics'),
                        icon: <FontAwesomeIcon icon={faChartSimple} />,
                        isProFeature: user?.features?.hasAnalyticsEnabled === false,
                        isActive: location.pathname === '/logistic-management/analytics/',
                        to: user?.features?.hasAnalyticsEnabled ? '/logistic-management/analytics/' : undefined,
                        dataTestid: 'nav-analytics',
                        showIf: !(user?.customer?.isLoaded ?? false) || canUserSeeLogisticManagementMenus,
                    },
                    {
                        title: t('Incidents'),
                        icon: <FontAwesomeIcon icon={faDiamondExclamation} />,
                        isActive: location.pathname === '/logistic-management/incidents/',
                        to: '/logistic-management/incidents/',
                        dataTestid: 'nav-incidents',
                        showIf: !(user?.customer?.isLoaded ?? false) || canUserSeeLogisticManagementMenus,
                    },
                    {
                        title: t('Warehouses'),
                        icon: <FontAwesomeIcon icon={faWarehouse} />,
                        isActive: location.pathname === '/warehouses/',
                        to: '/warehouses/',
                        showIf: user?.role === 'PROVIDER',
                    },
                    {
                        title: t('Documents'),
                        icon: <FontAwesomeIcon icon={faFolderOpen} />,
                        isActive: location.pathname === '/documents/',
                        to: '/documents/',
                        dataTestid: 'nav-documents',
                    },
                    ...(pdfConfigs.length > 0 && user.role === 'PROVIDER'
                        ? [
                              {
                                  title: t('Orders to review'),
                                  icon: <FontAwesomeIcon icon={faFileMagnifyingGlass} />,
                                  isActive: location.pathname.includes('/logistic-management/warehouses/emails/'),
                                  to: '/logistic-management/warehouses/emails/',
                                  countValue: countObject ? countObject[1] : undefined,
                                  dataTestid: 'storage-emails',
                              },
                          ]
                        : []),
                ]}
                warehouseNavigation={uniqBy(data ?? [], 'warehouse.id').map(({ id: configurationId, warehouse }) => {
                    const semiAutoBillingEnabledFirstConfiguration = (data ?? []).find(
                        (node) => node?.warehouse?.id === warehouse.id && node?.semiAutoBillingEnabled
                    );

                    return {
                        title: getWarehouseLabel(user, warehouse),
                        dataTestid: `storage-${warehouse?.id}`,
                        logoUrl: warehouse?.logisticProvider?.logoUrl
                            ? `${attachmentsUrl}/${warehouse?.logisticProvider?.logoUrl}`
                            : '',
                        isOpen:
                            warehouse?.id === expandedMenu ||
                            location.pathname.includes(`/logistic-management/warehouses/${warehouse.id}/`),
                        to: `/logistic-management/warehouses/${warehouse.id}/orders/`,
                        subItems: [
                            {
                                title: t('Orders'),
                                icon: <FontAwesomeIcon icon={faRoute} />,
                                isActive: location.pathname.includes(
                                    `/logistic-management/warehouses/${warehouse.id}/orders/`
                                ),
                                to: `/logistic-management/warehouses/${warehouse.id}/orders/`,
                                dataTestid: `storage-${warehouse?.id}-orders`,
                            },
                            {
                                title: t('Stock situation'),
                                icon: <FontAwesomeIcon icon={faBoxesStacked} />,
                                isActive: location.pathname.includes(
                                    `/logistic-management/warehouses/${warehouse.id}/inventory/stock-situation/`
                                ),
                                to: `/logistic-management/warehouses/${warehouse.id}/inventory/stock-situation/references/`,
                                dataTestid: `storage-${warehouse?.id}-stock`,
                            },
                            {
                                title: t('Configuration'),
                                icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
                                isActive: location.pathname.includes(
                                    `/logistic-management/warehouses/${warehouse.id}/warehouse-configurations/${configurationId}/`
                                ),
                                to: `/logistic-management/warehouses/${warehouse.id}/warehouse-configurations/${configurationId}/`,
                                dataTestid: `storage-${warehouse?.id}-conf`,
                            },
                            ...(user?.role === 'PROVIDER' && semiAutoBillingEnabledFirstConfiguration
                                ? [
                                      {
                                          title: t('Bills'),
                                          icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
                                          isActive: location.pathname.includes(
                                              `/logistic-management/warehouses/${semiAutoBillingEnabledFirstConfiguration.warehouse.id}/billing/`
                                          ),
                                          to: `/logistic-management/warehouses/${semiAutoBillingEnabledFirstConfiguration.warehouse.id}/billing/`,
                                          dataTestid: `storage-${warehouse?.id}-bill`,
                                      },
                                  ]
                                : []),
                        ],
                    };
                })}
                supportNavigation={[
                    {
                        title: t('Contact us'),
                        icon: <FontAwesomeIcon icon={faCommentsAlt} />,
                        href:
                            `https://help-spacefill.zendesk.com/hc/${(
                                user?.preferredLanguage ?? 'FR'
                            ).toLowerCase()}/requests/new` +
                            `?tf_anonymous_requester_email=${encodeURIComponent(user?.email)}` +
                            `&tf_1900005544053=${user?.role === 'SHIPPER' ? 'shipper' : 'partner'}` +
                            `&tf_4413256396561=${encodeURIComponent(`${user?.firstName} ${user?.lastName}`)}` +
                            (isStartUser ? '&ticket_form_id=4415414546961' : ''),
                        target: '_blank',
                    },
                    {
                        title: t('Help Center'),
                        icon: <FontAwesomeIcon icon={faQuestionCircle} />,
                        href: isStartUser
                            ? 'https://help-spacefill.zendesk.com/hc/fr/articles/15380143029521-Bienvenue-sur-Spacefill-Start-'
                            : `https://help-spacefill.zendesk.com/hc/${(
                                  user?.preferredLanguage ?? 'FR'
                              ).toLowerCase()}${
                                  ZendeskCategories?.[user?.role]
                                      ? `/categories/${ZendeskCategories?.[user?.role]}`
                                      : ''
                              }`,
                        target: '_blank',
                    },
                    ...(user?.role === 'SHIPPER' || user?.role === 'PROVIDER'
                        ? [
                              {
                                  title: t('Register for the training'),
                                  icon: <FontAwesomeIcon icon={faGraduationCap} />,
                                  href: `https://spacefill.typeform.com/to/${linkTrainingCenter}`,
                                  target: '_blank',
                              },
                          ]
                        : []),
                ]}
                bottomNavigation={[
                    {
                        title: t('Settings'),
                        icon: <FontAwesomeIcon icon={faGear} />,
                        dataTestid: 'nav-settings',
                        isActive: location.pathname.startsWith('/settings/'),
                        to: '/settings/users/',
                    },
                ]}
                profileTopNavigation={
                    user.multiRoles
                        ? [
                              {
                                  title: t('Operator view'),
                                  hasRoundedIcon: true,
                                  icon: <FontAwesomeIcon icon={faWarehouseFull} />,
                                  disabled: user.role === 'PROVIDER',
                                  dataTestid: `nav-switch-operator${user.role === 'PROVIDER' ? '-disabled' : ''}`,
                                  onClick: async () => await switchRole('PROVIDER'),
                              },
                              {
                                  title: t('Shipper view'),
                                  hasRoundedIcon: true,
                                  icon: <FontAwesomeIcon icon={faHandHoldingBox} />,
                                  disabled: user.role === 'SHIPPER',
                                  dataTestid: `nav-switch-shipper${user.role === 'SHIPPER' ? '-disabled' : ''}`,
                                  onClick: async () => await switchRole('SHIPPER'),
                              },
                          ]
                        : []
                }
                profileNavigation={[
                    {
                        title: t('Company profile'),
                        icon: <FontAwesomeIcon icon={faBuilding} />,
                        dataTestid: 'nav-company',
                        to: '/company-profile/',
                    },
                    {
                        title: t('User profile'),
                        icon: <FontAwesomeIcon icon={faUser} />,
                        dataTestid: 'nav-profile',
                        to: '/profile/',
                    },
                    {
                        title: t('Add new warehouses'),
                        icon: <FontAwesomeIcon icon={faLayerPlus} />,
                        dataTestid: 'nav-new-warehouse',
                        href: `https://help-spacefill.zendesk.com/hc/${
                            (user?.preferredLanguage ?? 'EN') === 'EN' ? 'en-us' : user?.preferredLanguage.toLowerCase()
                        }/requests/new?ticket_form_id=5609478305169&tf_anonymous_requester_email=${
                            user?.email
                        }&tf_1900005544053=shipper&tf_description=${t(
                            'I would like to add a new warehouse to my network'
                        )}`,
                        target: '_blank',
                    },
                    {
                        title: t('Log out'),
                        dataTestId: 'nav-logout',
                        icon: <FontAwesomeIcon icon={faArrowRightToBracket} />,
                        to: '/logout/',
                    },
                ]}
                userInfo={{
                    userName: `${user?.firstName} ${user?.lastName}`,
                    company: user?.customer?.company ?? user?.logisticProvider?.company,
                    role: user?.role,
                }}
            />
        </Fragment>
    );
};

export { ExternalConsoleLeftNav };
