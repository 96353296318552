import React from 'react';
import { Trans } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { faSolidBoxCircleExclamation } from '@awesome.me/kit-1e5a089522/icons/kit/custom';
import { faComment, faDiamondExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { t } from 'i18next';

export const templates = {
    comment_added_to_order: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='added a new comment on order <3>{{ orderReference }}</3>.'>
                    added a new comment on order <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#activities?commentId=${notification.templateParams.orderComment.id}`;
        },
    },
    comment_added_to_order_incident: {
        title() {
            return t('Comment');
        },
        icon() {
            return faComment;
        },
        content(notification = {}) {
            const incidentReference = notification.templateParams.incident.iid;
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='added a new comment on incident <1>{{ incidentReference }}</1> about order <3>{{ orderReference }}</3>.'>
                    added a new comment on incident <span className='font-semibold'>{{ incidentReference }}</span> about
                    order <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_incident_created: {
        title() {
            return t('Incident');
        },
        icon() {
            return faDiamondExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='created a new incident about order <1>{{ orderReference }}</1>.'>
                    created a new incident about order
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_incident_closed: {
        title() {
            return t('Incident');
        },
        icon() {
            return faDiamondExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            return (
                <Trans i18nKey='closed an incident about the order <1>{{ orderReference }}</1>.'>
                    closed an incident about the order
                    <span className='font-semibold'>{{ orderReference }}</span>.
                </Trans>
            );
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/#incidents`;
        },
    },
    order_completed_with_adjustment_to_shipper: {
        title(notification = {}) {
            return notification.templateParams.order.orderType === 'ENTRY'
                ? t('Entry')
                : notification.templateParams.order.orderType === 'EXIT'
                ? t('Exit')
                : '';
        },
        icon() {
            return faSolidBoxCircleExclamation;
        },
        content(notification = {}) {
            const orderReference =
                notification.templateParams.order.shipperOrderReference || notification.templateParams.order.iid;
            if (notification.templateParams.order.orderType === 'ENTRY') {
                return (
                    <Trans i18nKey='has marked <1>{{ orderReference }}</1> as received with inaccurate references.'>
                        has marked
                        <span className='font-semibold'>{{ orderReference }}</span> as received with inaccurate
                        references.
                    </Trans>
                );
            }
            if (notification.templateParams.order.orderType === 'EXIT') {
                return (
                    <Trans i18nKey='has marked <1>{{ orderReference }}</1> as shipped with inaccurate references.'>
                        has marked
                        <span className='font-semibold'>{{ orderReference }}</span> as shipped with inaccurate
                        references.
                    </Trans>
                );
            }
        },
        url(notification = {}) {
            return `/logistic-management/warehouses/${notification.templateParams.warehouse.id}/orders/${notification.templateParams.order.id}/`;
        },
    },
};
