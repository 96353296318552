import React from 'react';
import { Trans } from 'react-i18next';
import { faCalendarLines, faTruckClock, faListCheck, faCalendarExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { ChipIcon } from '@spacefill/uikit/src/components/ChipIcon';

export const templates = {
    // logistic_management_need_to_acknowledge_order_receipt_notification_to_warehouse
    WHS_MUST_ACKNOWLEDGE_RECEIPT_OF_ORDER_TODO_TASK_V0: {
        chipIcon() {
            return (
                <ChipIcon
                    icon={faListCheck}
                    color='blue'
                />
            );
        },
        content(todoTask = {}) {
            if (!todoTask?.order) {
                return '';
            }
            const orderReference = todoTask.order.shipperOrderReference || todoTask.order.iid;
            if (todoTask.order.orderType === 'ENTRY') {
                return (
                    <Trans i18nKey='Order <1>{{ orderReference }}</1> is ready to be completed. Please confirm receipt of goods.'>
                        Order <span className='font-semibold'>{{ orderReference }}</span> is ready to be completed.
                        Please confirm receipt of goods.
                    </Trans>
                );
            }
            if (todoTask.order.orderType === 'EXIT') {
                return (
                    <Trans i18nKey='Order <1>{{ orderReference }}</1> is ready to be completed. Please confirm shipment of goods.'>
                        Order <span className='font-semibold'>{{ orderReference }}</span> is ready to be completed.
                        Please confirm shipment of goods.
                    </Trans>
                );
            }
        },
        url(todoTask = {}) {
            return todoTask.order
                ? `/logistic-management/warehouses/${todoTask.order.warehouse.id}/orders/${todoTask.order.id}/`
                : null;
        },
    },
    // logistic_management_warehouse_needs_to_confirm_planned_execution_datetime_notification_to_warehouse
    WHS_MUST_ACCEPT_OR_DECLINE_PLANNED_EXECUTION_DATE_TODO_TASK_V0: {
        chipIcon() {
            return (
                <ChipIcon
                    icon={faCalendarLines}
                    color='blue'
                />
            );
        },
        content(todoTask = {}) {
            if (!todoTask?.order) {
                return '';
            }
            const orderReference = todoTask.order.shipperOrderReference || todoTask.order.iid;
            return (
                <Trans i18nKey='A time slot request has been sent for order <1>{{ orderReference }}</1>. Please confirm it or suggest another one.'>
                    A time slot request has been sent for order
                    <span className='font-semibold'>{{ orderReference }}</span>. Please confirm it or suggest another
                    one.
                </Trans>
            );
        },
        url(todoTask = {}) {
            return todoTask.order
                ? `/logistic-management/warehouses/${todoTask.order.warehouse.id}/orders/${todoTask.order.id}/`
                : null;
        },
    },
    // logistic_management_receipt_order_failed_notification_to_shipper
    SHIPPER_MUST_CANCEL_OR_RESCHEDULE_TODO_TASK_V0: {
        chipIcon() {
            return (
                <ChipIcon
                    icon={faTruckClock}
                    color='red'
                />
            );
        },
        content(todoTask = {}) {
            if (!todoTask?.order) {
                return '';
            }
            const orderReference = todoTask.order.shipperOrderReference || todoTask.order.iid;
            return (
                <Trans i18nKey='Order <1>{{ orderReference }}</1> has been marked as missed by the warehouse. Please reschedule or cancel it.'>
                    Order <span className='font-semibold'>{{ orderReference }}</span> has been marked as missed by the
                    warehouse. Please reschedule or cancel it.
                </Trans>
            );
        },
        url(todoTask = {}) {
            return todoTask.order
                ? `/logistic-management/warehouses/${todoTask.order.warehouse.id}/orders/${todoTask.order.id}/`
                : null;
        },
    },

    // logistic_management_shipper_needs_to_confirm_planned_execution_datetime_notification_to_shipper
    SHIPPER_MUST_SUGGEST_NEW_PLANNED_EXECUTION_DATE_TODO_TASK_V0: {
        chipIcon() {
            return (
                <ChipIcon
                    icon={faCalendarExclamation}
                    color='blue'
                />
            );
        },
        content(todoTask = {}) {
            if (!todoTask?.order) {
                return '';
            }
            const orderReference = todoTask.order.shipperOrderReference || todoTask.order.iid;
            return (
                <Trans i18nKey='The warehouse suggest another time slot for order <1>{{ orderReference }}</1>. Please accept it or suggest another one.'>
                    The warehouse suggest another time slot for order
                    <span className='font-semibold'>{{ orderReference }}</span>. Please accept it or suggest another
                    one.
                </Trans>
            );
        },
        url(todoTask = {}) {
            return `/logistic-management/warehouses/${todoTask.order.warehouse.id}/orders/${todoTask.order.id}/`;
        },
    },
};
