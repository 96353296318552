import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { getEnvironmentVariable } from '../utils/GetEnvVar';

const MapBoxRequest = () => {
    const mapboxAPIKey = getEnvironmentVariable('mapboxAPIKey');
    const mockMapboxAxiosRequestEnabled = getEnvironmentVariable('mockMapboxAxiosRequestEnabled');

    const axiosInstance = axios.create({
        baseURL: 'https://api.mapbox.com/',
        timeout: 5000,
        params: { access_token: mapboxAPIKey },
    });

    if (mockMapboxAxiosRequestEnabled) {
        const mock = new MockAdapter(axiosInstance);

        mock.onAny().reply((config) => {
            switch (config.url) {
                case '/directions/v5/mapbox/driving':
                    return [
                        200,
                        {
                            data: {
                                routes: [
                                    {
                                        distance: 5,
                                        duration: 5,
                                    },
                                ],
                            },
                        },
                    ];
                default:
                    return [500, {}];
            }
        });
    }

    return axiosInstance;
};

const LocalFrontRequest = () => {
    const mockLocalFrontAxiosRequestEnabled = getEnvironmentVariable('mockLocalFrontAxiosRequestEnabled');

    const axiosInstance = axios.create({
        baseURL: window.location.origin,
        timeout: 5000,
    });

    if (mockLocalFrontAxiosRequestEnabled) {
        const mock = new MockAdapter(axiosInstance);

        mock.onAny().reply((config) => {
            switch (config.url) {
                case '/version.json':
                    return [
                        200,
                        {
                            gitHash: 'mockGitHash',
                        },
                    ];
                default:
                    return [500, {}];
            }
        });
    }

    return axiosInstance;
};

const GibbonPdfRequest = () => {
    const axiosInstance = axios.create({
        baseURL: getEnvironmentVariable('gibbonPdfApiUrl'),
        timeout: 5000,
        responseType: 'blob',
    });

    return axiosInstance;
};

const SalesforceSyncRequest = () => {
    const axiosInstance = axios.create({
        baseURL: getEnvironmentVariable('salesforceSyncUrl'),
        timeout: 10000,
    });

    return axiosInstance;
};

export { MapBoxRequest, LocalFrontRequest, GibbonPdfRequest, SalesforceSyncRequest };
