import { SVGProps } from 'react';

const SvgWarehouse = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='644'
        height='512'
        viewBox='0 0 644 512'
        fill='none'
        {...props}
    >
        <path
            fill='currentColor'
            d='M331.522 17.885a24.118 24.118 0 0 0-18.293 0L41.779 129.267c-15.097 6.131-24.946 20.001-24.946 37.188v336.6c0 5.327-3.599 8.04-8.041 8.04-4.44 0-8.04-2.713-8.04-8.04v-336.6c0-22.815 13.8-43.419 34.916-52.063L307.099 3.008a40.293 40.293 0 0 1 30.554 0l271.471 111.384C630.23 123.036 644 143.64 644 166.455v336.6c0 5.327-3.618 8.04-8.041 8.04-4.422 0-8.04-2.713-8.04-8.04v-336.6c0-17.187-9.85-31.057-24.926-37.188L331.522 17.885ZM120.456 205.552c-3.518 0-8.041 4.523-8.041 8.041v289.462c0 5.327-2.713 8.04-8.04 8.04-3.538 0-8.04-2.713-8.04-8.04V213.593c0-12.463 11.658-24.122 24.121-24.122h402.935c13.367 0 24.122 11.659 24.122 24.122v289.462c0 5.327-3.619 8.04-8.041 8.04-4.422 0-8.04-2.713-8.04-8.04V213.593c0-3.518-3.619-8.041-8.041-8.041H120.456ZM451.025 383.35c17.79 0 32.163 14.373 32.163 32.163v64.325c0 17.789-14.373 32.162-32.163 32.162H192.821c-16.885 0-32.162-14.373-32.162-32.162V319.025c0-17.789 15.277-32.162 32.162-32.162H305.39c18.694 0 33.067 14.373 33.067 32.162v64.325h112.568ZM305.39 302.944H192.821c-7.94 0-16.081 7.237-16.081 16.081v64.325h144.731v-64.325c0-8.844-6.332-16.081-16.081-16.081Zm16.081 96.487H176.74v80.407c0 8.844 8.141 16.081 16.081 16.081h128.65v-96.488Zm16.986 96.488h112.568c8.845 0 16.082-7.237 16.082-16.081v-64.325c0-8.845-7.237-16.082-16.082-16.082H338.457v96.488Z'
        />
    </svg>
);
export default SvgWarehouse;
