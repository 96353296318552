import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';

type CommonTypes = {
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
};

export type VariantProps = {
    variant?: 'light' | 'dark' | 'red';
    value: number | string;
    hasBadge?: boolean;
};

type ChipProps = CommonTypes & VariantProps;

export const ChipNumber = ({
    variant = 'light',
    value,
    hasBadge = false,
    dataTestid,
    htmlAttributes = {},
}: ChipProps) => {
    const { i18n } = useTranslation();

    return (
        <div
            className={clsx('inline-flex min-w-4 items-center justify-center gap-2 rounded p-0.5 text-xs font-medium', {
                'bg-gray-100 text-gray-600': variant === 'light',
                'bg-zinc-700 text-white': variant === 'dark',
                'bg-red-500 text-white': variant === 'red',
                relative: hasBadge,
            })}
            data-testid={dataTestid}
            {...htmlAttributes}
        >
            {hasBadge && (
                <div
                    data-after=''
                    className={clsx([
                        'absolute -right-1 -top-1',
                        'size-2',
                        'bg-red-500',
                        'rounded-full border border-solid',
                        'after:content-[attr(data-after)]',
                        variant === 'dark' ? 'border-zinc-700' : 'border-white',
                    ])}
                />
            )}
            {typeof value === 'number' && value.toLocaleString(i18n.language.toLowerCase())}
            {typeof value === 'string' && value}
        </div>
    );
};
