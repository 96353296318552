import { Fragment, HTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { format, isSameYear, isToday } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingBox, faWarehouse } from '@fortawesome/pro-light-svg-icons';

import { Avatar, AvatarProps } from '../Avatar/Avatar';
import { Divider } from '../Divider';
import { ChipIcon } from '../ChipIcon';

export interface InAppNotificationProps {
    title: string;
    titleIcon: IconDefinition;
    warehouseName?: string;
    user: AvatarProps['user'];
    receivedAt: Date;
    customerCompany?: string;
    providerCompany?: string;
    unread?: boolean;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    children: ReactNode;
}

function formatRelativeDate(date: Date) {
    if (isToday(date)) {
        return format(date, "HH'h'mm");
    }
    if (isSameYear(date, new Date())) {
        return format(date, 'dd MMM');
    }
    return format(date, 'dd/MM/yy');
}

export const InAppNotification = ({
    title,
    titleIcon,
    warehouseName,
    user,
    receivedAt,
    customerCompany,
    providerCompany,
    unread = true,
    dataTestid,
    htmlAttributes,
    children,
}: InAppNotificationProps) => (
    <div
        data-testid={dataTestid}
        {...htmlAttributes}
        className={clsx('rounded-xl bg-white px-4 py-2 hover:bg-gray-100', unread ? 'opacity-100' : 'opacity-50')}
    >
        <div className='mb-2 flex items-center gap-1 text-sm text-gray-500'>
            <FontAwesomeIcon icon={titleIcon} />
            {title}
            {warehouseName && (
                <Fragment>
                    <Divider orientation='vertical' />
                    <FontAwesomeIcon icon={faWarehouse} />
                    <span data-testid={dataTestid && `${dataTestid}-warehousename`}>{warehouseName}</span>
                </Fragment>
            )}
        </div>
        <div className='relative mb-3 flex items-center gap-3'>
            {unread && (
                <div
                    data-testid={dataTestid && `${dataTestid}-badge`}
                    data-after=''
                    className={clsx([
                        'absolute -left-3 top-1/2 -mt-1',
                        'size-2',
                        'bg-red-500',
                        'rounded-full',
                        'after:content-[attr(data-after)]',
                    ])}
                />
            )}
            {user.role !== 'SERVICE' ? (
                <Avatar
                    dataTestid={dataTestid && `${dataTestid}-avatar`}
                    user={user}
                    variant='emerald'
                    showFullName
                    additionalText={customerCompany}
                />
            ) : (
                <div className='flex items-center gap-2'>
                    {providerCompany && (
                        <div
                            data-testid={dataTestid && `${dataTestid}-company`}
                            className='flex items-center gap-2 text-sm font-medium'
                        >
                            <ChipIcon icon={faWarehouse} />
                            {providerCompany}
                        </div>
                    )}
                    {customerCompany && (
                        <div
                            data-testid={dataTestid && `${dataTestid}-company`}
                            className='flex items-center gap-2 text-sm font-medium'
                        >
                            <ChipIcon icon={faHandHoldingBox} /> {customerCompany}
                        </div>
                    )}
                </div>
            )}
            <span className='text-xs text-gray-600'>{formatRelativeDate(receivedAt)}</span>
        </div>
        <div
            data-testid={dataTestid && `${dataTestid}-content`}
            className='text-sm'
        >
            {children}
        </div>
    </div>
);
