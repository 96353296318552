/* stylelint-disable selector-pseudo-class-disallowed-list */
import React, { Children, useEffect } from 'react';
import { Global, css, CacheProvider } from '@emotion/core';
import createCache from '@emotion/cache';
import { Helmet } from 'react-helmet';
import emotionReset from 'emotion-reset';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { palette, createTheme } from 'mineral-ui/themes';

import { useThemeStateContext } from '../utils/ThemeStateContext';
import { getEnvironmentVariable } from '../utils/GetEnvVar';

palette.gray[5] = '#FAFAFA';

palette.blue[15] = '#E4EDFB';

palette.yellow = {};
palette.yellow[10] = '#FFF4DF';
palette.yellow[20] = '#FFE6B9';
palette.yellow[30] = '#FFDFA5';
palette.yellow[40] = '#FFD283';
palette.yellow[50] = '#FFC762';
palette.yellow[60] = '#FFBB43';
palette.yellow[70] = '#E8A52F';
palette.yellow[80] = '#C59034';

const TailwindZincColorPalette = {
    10: '#F4F4F5',
    20: '#E4E4E7',
    30: '#D4D4D8',
    40: '#A1A1AA',
    50: '#71717A',
    60: '#27272a',
    70: '#27272a',
    80: '#27272a',
    90: '#27272a',
    100: '#27272a',
};

const mineralCache = createCache({
    key: 'mineral',
});

const initializeSpacefillTheme = () => {
    const customColorPalette = localStorage.getItem('spacefillCustomColorPalette');
    const mineralTheme = createTheme({
        colors: {
            theme:
                getEnvironmentVariable('customColorPaletteFeatureEnabled') && customColorPalette
                    ? palette[customColorPalette]
                    : TailwindZincColorPalette,
        },
    });

    const SpacefillTheme = {
        palette,
        Colors: {
            Primary: TailwindZincColorPalette[60],
            BackgroundGradientPrimary: 'linear-gradient(180deg, #FF6058, #E22C23)',
        },
        ...mineralTheme,
        backgroundColor_warningPrimary: palette.yellow[70],
        fontFamily:
            '"Inter",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontFamily_system:
            '"Inter",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        h1_fontSize: '1.85em',
        h1_fontWeight: 500,
        h2_fontSize: '1.65em',
        h2_fontWeight: 500,
        h3_fontWeight: 500,
        h4_fontWeight: 500,
        TooltipContent_color: palette.gray[100],
        TooltipContent_backgroundColor: palette.white,
        TooltipContent_borderColor: palette.gray[60],
        TooltipTriggerText_borderStyle: 'none',
        TooltipArrow_backgroundColor: palette.white,
        TooltipArrow_borderColor: palette.gray[60],
        fontSize: '14px',
        Layout: {
            Main: {
                Width: '1280px',
            },
            Header: {
                Height: '60px',
            },
            NotificationDrawer: {
                Height: '3rem',
            },
            LeftSidebar: {
                Width: '220px',
            },
            LeftDrawer: {
                Height: '60px',
            },
            ActionBarFixed: {
                Height: '60px', // Same as LeftDrawer
            },
        },
    };
    // Allow to use theme.palette.primary_color[60] instead of theme.color_theme_60 which is more convienient
    SpacefillTheme.palette.primary_color = {
        10: SpacefillTheme.color_theme_10,
        20: SpacefillTheme.color_theme_20,
        30: SpacefillTheme.color_theme_30,
        40: SpacefillTheme.color_theme_40,
        50: SpacefillTheme.color_theme_50,
        60: SpacefillTheme.color_theme_60,
        70: SpacefillTheme.color_theme_70,
        80: SpacefillTheme.color_theme_80,
        90: SpacefillTheme.color_theme_90,
        100: SpacefillTheme.color_theme_100,
    };

    return SpacefillTheme;
};

const SpacefillTheme = initializeSpacefillTheme();

const SpacefillThemeProvider = ({ children }) => {
    const { themeShouldUpdate, setThemeShouldUpdate } = useThemeStateContext();

    const SpacefillTheme = initializeSpacefillTheme();

    useEffect(() => {
        if (themeShouldUpdate) {
            setThemeShouldUpdate(false);
        }
    }, [themeShouldUpdate, setThemeShouldUpdate]);

    return (
        <CacheProvider value={mineralCache}>
            <EmotionThemeProvider theme={SpacefillTheme}>
                <Helmet>
                    <link
                        href='https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap'
                        rel='stylesheet'
                    />
                    <link
                        href='https://fonts.cdnfonts.com/css/campton'
                        rel='stylesheet'
                    />
                </Helmet>
                <Global
                    styles={css`
                        ${emotionReset}

                        html, body {
                            height: 100%;
                            font-family: ${SpacefillTheme.fontFamily};
                        }

                        #root {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: stretch;
                        }

                        /* Used to disable toast animations */
                        @keyframes dummy {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 1;
                            }
                        }

                        .dummy {
                            animation: dummy 0.001s;
                        }
                    `}
                />
                {Children.only(children)}
            </EmotionThemeProvider>
        </CacheProvider>
    );
};

export { SpacefillThemeProvider, SpacefillTheme };
