import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbItemRoot } from './Styled';

const BreadcrumbItem = (props) => {
    const { children, disabled, icon, index, selected, type, separator, ...restProps } = props;

    const rootProps = {
        'data-index': index,
        disabled,
        iconStart: icon,
        selected,
        type,
        ...restProps,
    };

    const sep = separator ? cloneElement(separator) : '/';

    return (
        <BreadcrumbItemRoot {...rootProps}>
            <Link to={rootProps.href}>{children}</Link>
            <span className='sep'>{sep}</span>
        </BreadcrumbItemRoot>
    );
};

BreadcrumbItem.displayName = 'BreadcrumbItem';

const LoadableBreadcrumbItem = ({ loaded: loadedText, loading: loadingText }, bcType) => {
    const [bcText, testId] = loadedText ? [loadedText, `${bcType}-loaded`] : [loadingText, `${bcType}-loading`];
    return <span data-testid={testId}>{bcText}</span>;
};

export { BreadcrumbItem, LoadableBreadcrumbItem };
