import React, { HTMLAttributes, ReactNode } from 'react';
import { format, isSameYear, isToday } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse } from '@fortawesome/pro-light-svg-icons';
import { clsx } from 'clsx';

import { ChipIcon } from '../ChipIcon';

export interface InAppNotificationProps {
    warehouseName: string;
    chipIcon: React.ReactElement<typeof ChipIcon>;
    receivedAt: Date;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    children: ReactNode;
}

function formatRelativeDate(date: Date) {
    if (isToday(date)) {
        return format(date, "HH'h'mm");
    }
    if (isSameYear(date, new Date())) {
        return format(date, 'dd MMM');
    }
    return format(date, 'dd/MM/yy');
}

export const RequiredAction = ({
    chipIcon,
    warehouseName,
    receivedAt,
    dataTestid,
    htmlAttributes,
    children,
}: InAppNotificationProps) => (
    <div
        data-testid={dataTestid}
        {...htmlAttributes}
        className={clsx('rounded-xl bg-white px-4 py-2 hover:bg-gray-100')}
    >
        <div className='mb-3 flex items-center justify-between text-sm text-gray-500'>
            <div className='flex items-center gap-1'>
                <FontAwesomeIcon icon={faWarehouse} />
                <span data-testid={dataTestid && `${dataTestid}-warehousename`}>{warehouseName}</span>
            </div>
            <div className='text-xs text-gray-600'>{formatRelativeDate(receivedAt)}</div>
        </div>
        <div className='relative flex items-start gap-3'>
            {chipIcon}

            <div
                data-testid={dataTestid && `${dataTestid}-content`}
                className='text-sm leading-6'
            >
                {children}
            </div>
        </div>
    </div>
);
