import { Dialog, Transition } from '@headlessui/react';
import { Fragment, HTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsRight } from '@fortawesome/pro-light-svg-icons';

import { ButtonText } from '../ButtonText';

export interface SidePanelProps {
    variant?: 'normal' | 'large';
    position?: 'left' | 'right';
    isOpen: boolean;
    onClose: () => void;
    title: ReactNode;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
    children: ReactNode;
    titleSideElement?: ReactNode;
}

export const SidePanel = ({
    variant = 'normal',
    isOpen,
    onClose,
    title,
    dataTestid,
    htmlAttributes,
    position = 'right',
    children,
    titleSideElement,
}: SidePanelProps) => (
    <Transition
        show={isOpen}
        as={Fragment}
    >
        <Dialog
            onClose={onClose}
            data-testid={dataTestid}
            autoFocus={true}
        >
            <Transition.Child
                enter='transition duration-100 ease-out'
                enterFrom={`transform ${
                    position === 'right' ? 'origin-right translate-x-full' : 'origin-left -translate-x-full'
                }`}
                enterTo='transform translate-x-0'
                leave='transition duration-100 ease-out'
                leaveFrom='transform translate-x-0'
                leaveTo={`transform ${
                    position === 'right' ? 'origin-right translate-x-full' : 'origin-left -translate-x-full'
                }`}
                as={Fragment}
            >
                <div
                    className={clsx(
                        'fixed inset-y-0 z-sidepanel flex h-screen items-stretch overflow-auto bg-white',
                        variant === 'large' ? 'w-128' : 'w-96',
                        {
                            'justify-start left-0 shadow-[8px_0_15px_0_rgba(0,0,0,0.08)]': position === 'left',
                            'justify-end right-0 shadow-[-8px_0_15px_0_rgba(0,0,0,0.08)]': position === 'right',
                        }
                    )}
                    {...htmlAttributes}
                >
                    <Dialog.Panel className='w-full'>
                        <Dialog.Title className='fixed z-sidepanel flex w-full items-center gap-2 bg-white p-6'>
                            <ButtonText
                                type='button'
                                onClick={onClose}
                                className='flex items-center gap-2'
                            >
                                <FontAwesomeIcon icon={faChevronsRight} />
                            </ButtonText>
                            <div className='mr-auto font-bold'>{title}</div>
                            {titleSideElement}
                        </Dialog.Title>
                        <div className='mt-14 p-6'>{children}</div>
                    </Dialog.Panel>
                </div>
            </Transition.Child>
        </Dialog>
    </Transition>
);
