import { HTMLAttributes } from 'react';

import logoQuick from './loading-quick.svg';
import logoLong from './loading-long.svg';

export interface LoaderProps {
    variant?: 'quick' | 'long';
    loadingMessage?: string;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLDivElement>;
}

export const Loader = ({ variant = 'quick', loadingMessage, dataTestid, htmlAttributes }: LoaderProps) => (
    <div
        className='flex flex-col items-center gap-2'
        data-testid={dataTestid}
        role='status'
        {...htmlAttributes}
    >
        {variant === 'quick' ? (
            <img
                className='animate-spin'
                alt='loading-quick'
                src={logoQuick}
            />
        ) : (
            <div className='relative'>
                <img
                    className='absolute opacity-10'
                    alt='loading-long'
                    src={logoLong}
                />
                <img
                    className='animate-[fillAndFade_1.5s_ease-out_infinite]'
                    src={logoLong}
                />
            </div>
        )}
        {loadingMessage && variant === 'long' && <p className='text-gray-500'>{loadingMessage}</p>}
    </div>
);
